import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../Styled/Container"

export const CollapsedSection = tw.section`w-full max-w-full`
export const SpacedSection = styled.section`
  ${tw`w-full max-w-full mb-5`}
  ${props => (props.condensed ? tw`md:mb-2` : tw`md:mb-5`)}
`

export const LargeWidthContainer = tw(Container)`max-w-lg`
export const FullWidthContainer = tw(Container)`max-w-full px-0 md:px-0 overflow-hidden`

export const Layouts = {
  default: CollapsedSection,
  No: CollapsedSection,
  Yes: SpacedSection,
}

export const Containers = {
  default: LargeWidthContainer,
  "Full Width": FullWidthContainer,
  Contained: LargeWidthContainer,
}
