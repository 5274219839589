import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { Layouts } from "../SectionsStyles"

export const withHeroBanner = Component => ({ name = `HeroBanner`, condensed, gradient, section }) => {
  const { trackPromoImpression, VisibilitySensor } = useAnalytics()
  const Section = section?.layout?.bottomSpacing ? Layouts[section.layout.bottomSpacing] : Layouts["default"]

  const slides = section.settings.slides ? parseInt(section.settings.slides) : 1

  const ratios = {
    desktop: {
      image: slides === 2 ? 8 / 9 : condensed ? 16 / 5 : 16 / 9,
      video: slides === 2 ? `8/9` : condensed ? `16/5` : `16/9`,
    },
    mobile: {
      image: condensed ? 3 / 4 : 9 / 16,
      video: `9/16`,
    },
  }

  Component.displayName = name
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...(section?.analytics || {}) })} partialVisibility={true}>
      <Component gradient={gradient} ratios={ratios} section={section} slides={slides} Section={Section} />
    </VisibilitySensor>
  )
}
