import ReactPlayer from "react-player/lazy"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../../Styled/Container"
import { SecondaryButton, TertiaryButton } from "../../Styled/Button"
import { P, H2, H3, H5 } from "../../Styled/Text"

export const Slide = styled.div`
  ${tw`relative w-full text-light`}
  ${props => (props.index === 0 ? tw`z-10` : ``)}
`
export const HeroContent = styled.div`
  ${tw`absolute inset-0 w-full pb-6-2`}
`
export const HeroSpacing = tw.div`pb-6 md:pb-9`
export const StyledContainer = styled(Container)`
  ${tw`h-full flex md:px-4-4 pt-10`}
  ${props =>
    props.alignment === "Left"
      ? tw`justify-center text-center md:justify-start md:text-left`
      : props.alignment === "Right"
      ? tw`justify-center text-center md:justify-end md:text-right`
      : tw`justify-center text-center`}
  ${props =>
    props.horizontalAlignment === "Top"
      ? tw`items-end md:items-start`
      : props.horizontalAlignment === "Center"
      ? tw`items-end md:items-center`
      : tw`items-end`}
`
export const ContentContainer = styled.div`
  ${tw`w-full md:max-w-1/2`}
`
export const ImageWrapper = tw.div`
  hidden md:block
`
export const MobileImageWrapper = tw.div`
  block md:hidden
`

export const StyledVideoWrapper = styled.div`
  ${props => (props.hideOnMobile ? tw`hidden md:block` : props.hideOnDesktop ? tw`block md:hidden` : ``)}
`
export const StyledVideo = styled(ReactPlayer)`
  ${tw`absolute`}
  ${props => (props.hideOnMobile ? tw`hidden md:block inset-0 w-full h-full block` : props.hideOnDesktop ? tw`block md:hidden top-0 left-0` : ``)}
`
export const StyledH2 = styled(H2)`
  ${tw`font-sans uppercase`}
  ${props => (props.textColour === "Dark" ? tw`text-dark` : ``)}
`
export const StyledH3 = styled(H3)`
  ${tw`font-sans uppercase`}
  ${props => (props.textColour === "Dark" ? tw`text-dark` : ``)}
`
export const StyledH5 = styled(H5)`
  ${tw``}
  ${props => (props.textColour === "Dark" ? tw`text-dark` : ``)}
`
export const StyledP = styled(P)`
  ${tw`text-md tracking-tight mt-3 text-base md:text-md`}
  ${props => (props.textColour === "Dark" ? tw`text-dark` : ``)}
  ${props => (props.alignment === "Left" ? tw`md:mr-9` : props.alignment === "Right" ? tw`md:ml-9` : ``)}
`
export const Buttons = styled.div`
  ${tw`flex items-center justify-center mt-4-2 -mx-1-6`} ${props =>
    props.alignment === "Left"
      ? tw`justify-center text-center md:justify-start md:text-left`
      : props.alignment === "Right"
      ? tw`justify-center text-center md:justify-end md:text-right`
      : tw`justify-center text-center`}
`
export const StyledSecondaryButton = styled(SecondaryButton)`
  ${tw`block min-w-13-2 mx-1-6 py-1-4`}
  ${props => (props.textColour === "Dark" ? tw`bg-dark border-dark text-light hover:bg-light hover:text-dark` : ``)}
`
export const StyledTertiaryButton = styled(TertiaryButton)`
  ${tw`block min-w-13-2 mx-1-6 py-1-4`}
  ${props => (props.textColour === "Dark" ? tw`bg-dark border-dark text-light hover:bg-light hover:text-dark` : ``)}
`
