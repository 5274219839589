export const useSection = () => {
  const transformSection = (item, index = 0) => {
    const { _type, slice_type, primary, items, settings, content, images, articles, ...rest } = item || {}

    const type = slice_type ? slice_type : _type
    const id = slice_type ? `${type}_${index}` : item?._key ? item._key : _type
    const itemSettings = primary ? primary : settings ? settings : {}
    const blocks = items ? items : content ? content : images ? images : articles ? articles : []

    const section = {
      ...rest,
      type,
      id,
      settings: itemSettings,
      blocks,
    }

    return section
  }

  return { transformSection }
}
