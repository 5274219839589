import React from "react"
import Glider from "react-glider"
import { Link } from "gatsby"

import { withHeroBanner } from "./withHeroBanner"
import { Image } from "../../Image/Image"
import { RichText } from "../../RichText/RichText"
import {
  Buttons,
  HeroContent,
  HeroSpacing,
  ContentContainer,
  ImageWrapper,
  MobileImageWrapper,
  StyledContainer,
  StyledP,
  StyledH2,
  StyledH3,
  StyledH5,
  StyledVideo,
  StyledVideoWrapper,
  Slide,
  StyledSecondaryButton,
  StyledTertiaryButton,
} from "./HeroBannerStyles"

interface Props {
  condensed: any
  gradient: any
  ratios: any
  section: any
  slides: Array<any>
  Section: any
}

export const HeroBanner = withHeroBanner(({ gradient, ratios, section, slides, Section }: Props) =>
  section?.blocks?.length > 0 ? (
    <Section id={section.id}>
      {section.blocks.length > 1 ? (
        <Glider
          draggable
          duration={0.3}
          hasDots={section.blocks.length > slides}
          scrollLock
          slidesToShow={slides}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 778,
              settings: {
                hasDots: false,
                slidesToShow: 1,
              },
            },
          ]}
        >
          {section.blocks.map((block, i) => (
            <Slide key={block.key} index={i}>
              <>
                <ImageWrapper>
                  <Image
                    src={block.image}
                    ratio={block.image ? ratios[`desktop`].image : ratios[`desktop`].video}
                    alt={block.image?.alt}
                    gradient={gradient}
                  />
                </ImageWrapper>

                <MobileImageWrapper>
                  <Image
                    src={block.imageMobile || block.image}
                    ratio={block.imageMobile || block.image ? ratios[`mobile`].image : ratios[`mobile`].video}
                    alt={block.image?.alt}
                  />
                </MobileImageWrapper>
              </>

              {block.video ? (
                <StyledVideoWrapper hideOnMobile={block.videoMobile?.link ? true : false}>
                  <StyledVideo
                    hideOnMobile={block.videoMobile?.link ? true : false}
                    url={block.video.link}
                    playing
                    loop
                    playsinline={true}
                    muted
                    volume={0}
                    width={`100%`}
                    height={`100%`}
                  />
                </StyledVideoWrapper>
              ) : null}

              {block.videoMobile ? (
                <StyledVideoWrapper hideOnDesktop>
                  <StyledVideo
                    hideOnDesktop
                    url={block.videoMobile.link}
                    playing
                    loop
                    playsinline={true}
                    muted
                    volume={0}
                    width={`100%`}
                    height={`100%`}
                  />
                </StyledVideoWrapper>
              ) : null}

              <HeroContent>
                <StyledContainer alignment={block.alignment} horizontalAlignment={block.horizontalAlignment} width={`lg`}>
                  <ContentContainer>
                    {block.heading ? (
                      block.headingSize === `Large` ? (
                        <StyledH2 as={`h1`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH2>
                      ) : block.headingSize === `Small` ? (
                        <StyledH5 as={`h3`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH5>
                      ) : (
                        <StyledH3 as={`h2`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH3>
                      )
                    ) : null}

                    {block.subheading && (
                      <StyledP as={`p`} textColour={block.textColour} alignment={block.alignment}>
                        <RichText content={block.subheading} />
                      </StyledP>
                    )}

                    {(block.primaryButtonText && block.primaryButtonLink) || (block.secondaryButtonText && block.secondaryButtonLink) ? (
                      <Buttons alignment={block.alignment}>
                        {block.primaryButtonText && block.primaryButtonLink?.match(/http(s)?:\/\//i) ? (
                          <StyledSecondaryButton as={"a"} href={block.primaryButtonLink} target={`_blank`} textColour={block.textColour}>
                            {block.primaryButtonText}
                          </StyledSecondaryButton>
                        ) : block.primaryButtonText && block.primaryButtonLink ? (
                          <StyledSecondaryButton as={Link} to={block.primaryButtonLink} textColour={block.textColour}>
                            {block.primaryButtonText}
                          </StyledSecondaryButton>
                        ) : null}

                        {block.secondaryButtonText && block.secondaryButtonLink?.match(/http(s)?:\/\//i) ? (
                          <StyledTertiaryButton as={"a"} href={block.secondaryButtonLink} textColour={block.textColour}>
                            {block.secondaryButtonText}
                          </StyledTertiaryButton>
                        ) : block.secondaryButtonText && block.secondaryButtonLink ? (
                          <StyledTertiaryButton as={Link} to={block.secondaryButtonLink} textColour={block.textColour}>
                            {block.secondaryButtonText}
                          </StyledTertiaryButton>
                        ) : null}
                      </Buttons>
                    ) : null}
                  </ContentContainer>
                </StyledContainer>
              </HeroContent>
            </Slide>
          ))}
        </Glider>
      ) : (
        <>
          {section.blocks.map((block, i) => (
            <Slide key={block.key} index={i}>
              <>
                <ImageWrapper>
                  <Image
                    src={block.image}
                    ratio={block.image ? ratios[`desktop`].image : ratios[`desktop`].video}
                    alt={block.image?.alt}
                    gradient={gradient}
                  />
                </ImageWrapper>

                <MobileImageWrapper>
                  <Image
                    src={block.imageMobile || block.image}
                    ratio={block.imageMobile || block.image ? ratios[`mobile`].image : ratios[`mobile`].video}
                    alt={block.image?.alt}
                  />
                </MobileImageWrapper>
              </>

              {block.video ? (
                <StyledVideoWrapper hideOnMobile={block.videoMobile?.link ? true : false}>
                  <StyledVideo
                    hideOnMobile={block.videoMobile?.link ? true : false}
                    url={block.video.link}
                    playing
                    loop
                    playsinline={true}
                    muted
                    volume={0}
                    width={`100%`}
                    height={`100%`}
                  />
                </StyledVideoWrapper>
              ) : null}

              {block.videoMobile ? (
                <StyledVideoWrapper hideOnDesktop>
                  <StyledVideo
                    hideOnDesktop
                    url={block.videoMobile.link}
                    playing
                    loop
                    playsinline={true}
                    muted
                    volume={0}
                    width={`100%`}
                    height={`100%`}
                  />
                </StyledVideoWrapper>
              ) : null}

              <HeroContent>
                <StyledContainer alignment={block.alignment} horizontalAlignment={block.horizontalAlignment} width={`lg`}>
                  <ContentContainer>
                    {block.heading ? (
                      block.headingSize === `Large` ? (
                        <StyledH2 as={`h1`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH2>
                      ) : block.headingSize === `Small` ? (
                        <StyledH5 as={`h3`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH5>
                      ) : (
                        <StyledH3 as={`h2`} textColour={block.textColour}>
                          <RichText content={block.heading} />
                        </StyledH3>
                      )
                    ) : null}

                    {block.subheading && (
                      <StyledP as={`p`} textColour={block.textColour} alignment={block.alignment}>
                        <RichText content={block.subheading} />
                      </StyledP>
                    )}

                    {(block.primaryButtonText && block.primaryButtonLink) || (block.secondaryButtonText && block.secondaryButtonLink) ? (
                      <Buttons alignment={block.alignment}>
                        {block.primaryButtonText && block.primaryButtonLink?.match(/http(s)?:\/\//i) ? (
                          <StyledSecondaryButton as={"a"} href={block.primaryButtonLink} target={`_blank`} textColour={block.textColour}>
                            {block.primaryButtonText}
                          </StyledSecondaryButton>
                        ) : block.primaryButtonText && block.primaryButtonLink ? (
                          <StyledSecondaryButton as={Link} to={block.primaryButtonLink} textColour={block.textColour}>
                            {block.primaryButtonText}
                          </StyledSecondaryButton>
                        ) : null}

                        {block.secondaryButtonText && block.secondaryButtonLink?.match(/http(s)?:\/\//i) ? (
                          <StyledTertiaryButton as={"a"} href={block.secondaryButtonLink} textColour={block.textColour}>
                            {block.secondaryButtonText}
                          </StyledTertiaryButton>
                        ) : block.secondaryButtonText && block.secondaryButtonLink ? (
                          <StyledTertiaryButton as={Link} to={block.secondaryButtonLink} textColour={block.textColour}>
                            {block.secondaryButtonText}
                          </StyledTertiaryButton>
                        ) : null}
                      </Buttons>
                    ) : null}
                  </ContentContainer>
                </StyledContainer>
              </HeroContent>
            </Slide>
          ))}
        </>
      )}
    </Section>
  ) : (
    <HeroSpacing />
  )
)

export default HeroBanner
